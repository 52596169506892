import * as React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Explicit icon imports allow for treeshaking
import faTwitter from '@fortawesome/fontawesome-free-brands/faTwitter';
import faFacebook from '@fortawesome/fontawesome-free-brands/faFacebookF';
import faLinkedin from '@fortawesome/fontawesome-free-brands/faLinkedin';
import faYoutube from '@fortawesome/fontawesome-free-brands/faYoutube';
import faInstagram from '@fortawesome/fontawesome-free-brands/faInstagram';
import faSearch from '@fortawesome/fontawesome-free-solid/faSearch';
import faAngleDown from '@fortawesome/fontawesome-free-solid/faAngleDown';
import faAngleRight from '@fortawesome/fontawesome-free-solid/faAngleRight';
import faAngleLeft from '@fortawesome/fontawesome-free-solid/faAngleLeft';
import faAngleUp from '@fortawesome/fontawesome-free-solid/faAngleUp';
import faCalendarAlt from '@fortawesome/fontawesome-free-solid/faCalendarAlt';
import faCheckCircle from '@fortawesome/fontawesome-free-solid/faCheckCircle';
import faPlayCircle from '@fortawesome/fontawesome-free-regular/faPlayCircle';
import faTimes from '@fortawesome/fontawesome-free-solid/faTimes';
import faTimesCircle from '@fortawesome/fontawesome-free-solid/faTimesCircle';
import faUser from '@fortawesome/fontawesome-free-solid/faUser';
import faGlobe from '@fortawesome/fontawesome-free-solid/faGlobe';
import faEnvelope from '@fortawesome/fontawesome-free-solid/faEnvelope';
import faLock from '@fortawesome/fontawesome-free-solid/faLock';
import faPencil from '@fortawesome/fontawesome-free-solid/faPencilAlt';
import faChevronRight from '@fortawesome/fontawesome-free-solid/faChevronRight';
import faChevronLeft from '@fortawesome/fontawesome-free-solid/faChevronLeft';
import faCalendar from '@fortawesome/fontawesome-free-solid/faCalendar';
import faHeart from '@fortawesome/fontawesome-free-solid/faHeart';
import faShoppingCart from '@fortawesome/fontawesome-free-solid/faShoppingCart';
import faCalculator from '@fortawesome/fontawesome-free-solid/faCalculator';
import faCreditCard from '@fortawesome/fontawesome-free-solid/faCreditCard';
import faHome from '@fortawesome/fontawesome-free-solid/faHome';
// import {faCheckCircle as faCheckCircleLight} from '@fortawesome/fontawesome-free-regular/faCheckCircle';

// @TODO: Either keep with the 'fa' prefix or get rid of it.
const icons = {
  faTwitter,
  faFacebook,
  faYoutube,
  faInstagram,
  faLinkedin,
  faSearch,
  faCalendarAlt,
  faCalendar,
  faCheckCircle,
  faCreditCard,
  faHome,
  down: faAngleDown,
  right: faAngleRight,
  left: faAngleLeft,
  up: faAngleUp,
  email: faEnvelope,
  lock: faLock,
  pencil: faPencil,
  faPlayCircle,
  faTimes,
  faTimesCircle,
  faUser,
  faGlobe,
  faChevronRight,
  faChevronLeft,
  faHeart,
  faShoppingCart,
  faCalculator,
};

const Icon = ({ type, ...props }) => (
  <FontAwesomeIcon {...props} icon={icons[type]} />
);

Icon.propTypes = {
  type: PropTypes.string.isRequired,
};
export default Icon;
