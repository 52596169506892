import * as React from "react";
import { css } from "@emotion/core";
import PropTypes from "prop-types";
import { Link } from "@fitplan/lib/components/Link";

import Icon from "../../components/icons";
import CreditCardIcon from "./credit-card-icon"; // custom icon for Accounts section.
import "./card.scss";

interface Props {
    SVGIcon?: React.ReactNode;
    rightNode?: React.ReactNode;
    titleText?: string;
    iconType?: string;
    iconBackgroundColor?: string;
    iconColor?: string;
    className?: string;

    mainLink?: string;
    mainLinkLabel?: string;
    mainLinkIcon?: string;
}

const Card: React.FunctionComponent<Props> = ({
    mainLinkIcon = "pencil",
    ...props
}) => {
    // Icon in top-right section used in link.
    const linkIcon =
        mainLinkIcon === "credit-card-icon" ? (
            <CreditCardIcon width="21px" height="16px" color="#12b587" />
        ) : (
            <Icon
                type={mainLinkIcon}
                css={css`
                    color: #12b587;
                `}
            />
        );

    return (
        <div className="bg-white overflow-hidden shadow-lg sm:rounded-lg my-8 sm:mx-8">
            <div className="px-4 py-5 sm:p-6">
                <div
                    data-testid="card__container-icon"
                    className="flex flex-no-wrap items-center content-center justify-between"
                >
                    {props.titleText ||
                    props.iconType ||
                    props.SVGIcon ||
                    props.titleText ? (
                        <div className="leftNode flex flex-no-wrap">
                            {props.iconType ? (
                                <Icon
                                    css={css`
                                        font-size: 40px;
                                        color: ${props.iconBackgroundColor
                                            ? props.iconBackgroundColor
                                            : "#000"};
                                        background-color: ${props.iconColor
                                            ? props.iconColor
                                            : "#fff"};
                                        margin: 0;
                                    `}
                                    type={props.iconType}
                                />
                            ) : (
                                ""
                            )}
                            {props.SVGIcon ? props.SVGIcon : ""}
                            {props.SVGIcon || props.iconType ? (
                                <div style={{ width: "16px" }} />
                            ) : (
                                ""
                            )}
                            {props.titleText ? (
                                <h1
                                    data-testid="card__container-title"
                                    css={css`
                                        font-family: Barlow Condensed;
                                        font-size: 24px;
                                        font-weight: bold;
                                        font-style: normal;
                                        font-stretch: condensed;
                                        line-height: 1.85;
                                        letter-spacing: normal;
                                        color: #000;
                                    `}
                                >
                                    {props.titleText}
                                </h1>
                            ) : (
                                ""
                            )}
                        </div>
                    ) : (
                        <div>&nbsp;</div>
                    )}
                    {props.rightNode ? (
                        <div className="rightNode hidden md:block">{props.rightNode}</div>
                    ) : (
                        ""
                    )}
                    {props.mainLink ? (
                        <div className="hidden md:block">
                            <Link
                                to={props.mainLink}
                                className="flex flex-no-wrap align-items-center"
                            >
                                {linkIcon}
                                <p
                                    css={css`
                                        color: #12b587;
                                        display: inline;
                                        margin-left: 16px;
                                    `}
                                    className="-mt-1"
                                >
                                    {props.mainLinkLabel
                                        ? props.mainLinkLabel
                                        : props.mainLink}
                                </p>
                            </Link>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
                <div className="py-4 px-2">
                    {props.children}
                    <div className="bottomNode md:hidden">{props.rightNode}</div>
                    {props.mainLink && (
                        <div className="block md:hidden">
                            <Link
                                to={props.mainLink}
                                className="flex flex-no-wrap align-items-center"
                            >
                                {linkIcon}
                                <p
                                    css={css`
                                        color: #12b587;
                                        display: inline;
                                        margin-left: 16px;
                                    `}
                                    className="-mt-1"
                                >
                                    {props.mainLinkLabel
                                        ? props.mainLinkLabel
                                        : props.mainLink}
                                </p>
                            </Link>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Card;
