import * as React from 'react';
import { css } from "@emotion/core";
import PropTypes from 'prop-types';

class CreditCardIcon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <svg
        viewBox="0 0 21 16"
        className={'account__credit-card-icon ' + this.props.className || ''}
        css={css`
          width: ${this.props.width};
          height: ${this.props.height};
        `}
      >
        <g
          fill="none"
          fillRule="evenodd"
          stroke={this.props.color}
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M5.25 11.109H9M15.25 11.109h1.25M20.25 6.109H1.5M19 14.859H2.75c-.69 0-1.25-.56-1.25-1.25V2.359c0-.69.56-1.25 1.25-1.25H19c.69 0 1.25.56 1.25 1.25v11.25c0 .69-.56 1.25-1.25 1.25z" />
        </g>
      </svg>
    );
  }
}

CreditCardIcon.defaultProps = {
  color: '#000000',
  width: '21px',
  height: '16px',
};

CreditCardIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default CreditCardIcon;
